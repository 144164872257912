import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "normalize.css";
import "./plugins/element.js";
import * as filters from "@/fliters";
import "@/plugins/rem.js";
Vue.config.productionTip = false;
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
