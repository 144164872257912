import Moment from "moment";
// 权限过滤器
export function rank(v) {
  let result;
  switch (v + "") {
    case "0":
      result = "一级";
      break;
    case "1":
      result = "二级";
      break;
    case "2":
      result = "三级";
      break;
    default:
      result = "暂未分级";
  }
  return result;
}

// 时间戳过滤
export function timeData(v) {
  const dt = Moment(v * 1000).format("YYYY-MM-DD  HH:mm:ss");
  return dt;
}
