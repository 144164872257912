import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "@/views/Login"),
  },
  {
    path: "/home",
    name: "home",
    meta: {
      auth: true,
    },
    redirect: "/welcome",
    component: () => import("@/views/Home"),
    children: [
      {
        path: "/welcome",
        name: "welcome",
        component: () => import("@/views/Home/Welcome"),
      },
      {
        path: "/carmiVerification",
        name: "carmiVerification",
        component: () => import("@/views/Home/CarmiVerification"),
      },
      {
        path: "/softwareManagement",
        name: "softwareManagement",
        component: () => import("@/views/Home/SoftwareManagement"),
      },
      {
        path: "/recordsCenter",
        name: "recordsCenter",
        component: () => import("@/views/Home/RecordsCenter"),
      },
      {
        path: "/recordsCenterUp",
        name: "recordsCenterUp",
        component: () => import("@/views/Home/RecordsCenterUp"),
      },
      {
        path: "/userCenter",
        name: "userCenter",
        component: () => import("@/views/Home/UserCenter"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  const flag = to.matched.some((item) => {
    console.log(item);
    return item.meta.auth;
  });
  if (!flag) {
    next();
  } else {
    const token = sessionStorage.getItem("token");
    if (!token) {
      return next({
        path: "/login",
      });
    }
    next();
  }
  next();
});

export default router;
